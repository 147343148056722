import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/reviewPage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ReviewHeader = makeShortcode("ReviewHeader");
const ReviewFooter = makeShortcode("ReviewFooter");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ReviewHeader data={props.pageContext.frontmatter} mdxType="ReviewHeader" />
    <h1>{`Overall`}</h1>
    <p>{`Worth a try hole in the wall. An obvious stop for the nearby college students, they got the macaroni
salad hook-up! An interesting lineup of fish, sauce and topping creations await anyone bored of the usual
poké bar fixings. We weren't expecting much from this place but honestly they suprised us. Definitely worth
a visit, especially if you are looking for something a little different.`}</p>
    <h1>{`Key Points:`}</h1>
    <ul>
      <li parentName="ul">{`fun & unique toppings but not a massive variety.`}</li>
      <li parentName="ul">{`The offer of macaroni salad for a base threw us for a loop, unfortunately we had to
turn it down because of dietary restrictions. @ us if you have had experience with it.`}</li>
      <li parentName="ul">{`Good quality fish with interesting flavorings. sadly no plain options and only moderately sized portions.`}</li>
      <li parentName="ul">{`The ginger is pink (it's not even wednesday?). And they will give you more, but they don't pile it on heavy.`}</li>
      <li parentName="ul">{`Pro tip: pick sushi rice.`}</li>
      <li parentName="ul">{`Most eco-friendly place we have seen. 😍`}</li>
    </ul>
    <h1>{`Biggest Suggestion`}</h1>
    <p>{`Offer plain fish options for people with dietary restrictions.`}</p>
    <ReviewFooter data={props.pageContext.frontmatter} mdxType="ReviewFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      